import { useEffect } from "react";
import client from "../api/client";

export const isValidEmail = (email) => {
  const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return isValid.test(email);
};

export const getToken = () => localStorage.getItem("auth-token");

export const catchError = (error) => {
  const { response } = error;
  if (response?.data) return response.data;

  return { error: error.message || error };
};

export const getPoster = (posters = []) => {
  const { length } = posters;

  if (!length) return null;

  // if poster has more then 2 items then selecting second poster.
  if (length > 2) return posters[1];

  // other wise the first one
  return posters[0];
};

export const convertReviewCount = (count = 0) => {
  if (count <= 999) return count;

  return parseFloat(count / 1000).toFixed(2) + "k";
};

export default function useClickOutside(ref, fun) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      fun();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref]);
}

export const fetchGeneralSettings = async () => {
  try {
    const { data } = await client.get("/general-setting/settings");
    return data;
  } catch (error) {
    console.error("Error fetching settings:", error);
    return null;
  }
};

export const getCurrencyIcon = async () => {
  try {
    const { data } = await client.get("/general-setting/currencyIcon");
    return data;
  } catch (error) {
    console.error("Error fetching settings:", error);
    return null;
  }
};

// export const formatCurrencyIcon = (amount, currencyIcon, currencyCode) => {
//   if (!amount || isNaN(amount)) return "";
//   if (!currencyIcon) return amount;
//   return new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: currencyCode,
//   })?.format(amount);
// };

export const formatCurrency = (
  amount,
  currencyIcon = "₦",
  currencyCode = "NGN"
) => {
  if (amount === undefined || isNaN(amount)) return "";

  try {
    const formattedAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "code", // Display currency code (we'll manually replace it)
    })?.format(amount);

    // Manually replace 'NGN' with '₦'
    return formattedAmount?.replace(currencyCode, currencyIcon);
  } catch (error) {
    console.error("Error formatting currency:", error);
    return `${currencyIcon}${amount?.toFixed(2)}`;
  }
};

export const fetchSettings = async () => {
  try {
    const { data } = await client.get("/setting/paystack");
    return data;
  } catch (error) {
    console.error("Error fetching settings:", error);
    return catchError(error);
  }
};

export const Loader = ({ className }) => {
  <div
    className={`${
      className +
      " inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"
    }`}
  >
    {/* Loading indicator */}
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};
